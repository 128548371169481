// PAGE HOME

.page__home {
    padding-top: 71px; // 73 // space header

    // burger
    @media screen and (max-width: 800px) {
        padding-top: 0;
    }

    .banner {
        // background-color: $lightgray;
        // background: $primary;
        // background: linear-gradient(rgba($primary, .75), rgba($primary, .75)), url('../img/banner-home.jpg') no-repeat center center/cover;

        > .wrapper { // --none
            z-index: 4;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center; 
        
            height: 500px;
        }

        &__title { // maintitle--large
            // specific
            margin-bottom: .3em;
            padding-bottom: .3em;
        }

        &__subtitle {} // maintitle--small --nodeco

        &__buttonsContainer {
            position: absolute;
            z-index: 3;
            bottom: 20px;
            right: 50px;

            display: flex;
            align-items: center;

            @media screen and (max-width: 900px) {
                right: 5%;
            }
            @media screen and (max-width: 600px) {
                right: 5%;
                left: 5%;                
                
                flex-direction: column;
                align-items: center;
                justify-content: center;
            } 

            &__button { // .button
                margin-left: 10px;

                @media screen and (max-width: 600px) {
                    margin-left: 5px;
                    margin-right: 5px;
                    margin-bottom: 10px;        
                } 
            }
        } 
        
        &__bcg { // img outside wrapper
            z-index: 1;

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;

            // overlay
            &::before {
                z-index: 3;
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba($white, 0.8);
                // background: linear-gradient(131deg, rgba($white, .8) 0%, rgba($white, .8) 78%, rgba($white, 0) 100%);
            }

            &__img {
                z-index: 2;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center top;
                // opacity: .15;
            }
        }
    }

    .apropos {
        padding-top: calc(60px + 60px);
        padding-bottom: 60px;

        @media screen and (max-width: 1000px) {
            padding-top: calc(40px + 40px);
            padding-bottom: 40px;
        }
        @media screen and (max-width: 500px) {
            padding-top: calc(30px + 10px);
            padding-bottom: 30px;
        }

        &--2 { // section certifications
            padding-top: 60px;
            padding-bottom: 60px;

            @media screen and (max-width: 1000px) {
                padding-top: 40px;
                padding-bottom: 40px;
            }
            @media screen and (max-width: 500px) {
                padding-top: 30px;
                padding-bottom: 30px;
            }

            > .wrapper {
                @media screen and (max-width: 800px) {
                    grid-template-columns: repeat(1, 1fr) !important;
                    gap: 40px !important;
                }
            }

            .apropos__contentContainer {
                order: 1;

                &__content {
                    @media screen and (max-width: 800px) {
                        max-width: 560px;
                    }
                }
            }
            .apropos__teamContainer {
                order: 2;
            }
        }

        > .wrapper {
            position: relative;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 60px;
            // align-items: center;

            @media screen and (max-width: 700px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 40px;
            }
        }

        &__contentContainer {
            width: 100%;
            order: 2;

            @media screen and (max-width: 700px) {
                order: 1;
            }

            &__title {} // maintitle
            
            &__content { // div
                p {
                    margin-bottom: 1em;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                ul {
                    list-style: none;
                    padding-left: 1em;
                    margin-bottom: 1em;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    li {
                        position: relative;
                        display: block;
                        margin-left: 1em;

                        &::before {
                            content: "\2022";
                            position: absolute;
                            top: -1px;
                            left: -1em;
                            color: $textLight;
                            width: 1em;
                        }
                    }
                }
            } 

            // &__buttonContainer {
            //     margin-top: 40px;

            //     &__text {
            //         font-weight: bold;
            //     }
            //     &__button { // .button
            //         &--1 {
            //             margin-right: 10px;
            //         }
            //         &--2 {}
            //     }
            // }
        }

        &__teamContainer {
            width: 100%;
            order: 1;

            position: relative;
            display: grid;
            // grid-template-columns: repeat(2, 1fr);
            // gap: 30px;

            @media screen and (max-width: 700px) {
                order: 2;
            }

            &__member {
                width: 100%;

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-self: center;
                text-align: center;

                &__img {
                    margin-bottom: 20px;
                    // border-radius: 8px;
                }

                &__name { // maintitle--smaller --nodeco
                    // specific
                    font-weight: normal;
                    margin-bottom: 0;        
                }

                &__role {
                    
                }
            }
        }
    }

    .competences { // missions
        // padding-top: 60px;
        padding-bottom: 60px;

        @media screen and (max-width: 1000px) {
            // padding-top: 40px;
            padding-bottom: 40px;
        }
        @media screen and (max-width: 500px) {
            // padding-top: 30px;
            padding-bottom: 30px;
        }
        
        > .wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            // gap: 30px;
        }

        &__heading {
            &__title {} // maintitle
        }

        &__itemsContainer {
            width: 100%;

            position: relative;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 50px;

            @media screen and (max-width: 1000px) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media screen and (max-width: 700px) {
                grid-template-columns: repeat(1, 1fr);
            }

            &__item {
                width: 100%;

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                text-align: center;

                &__img {
                    margin-left: auto;
                    margin-right: auto;
                    width: 40px;
                    margin-bottom: 10px;

                    @media screen and (max-width: 1000px) {
                        width: 30px;
                    }
                }

                &__title {} // maintitle--small --nodeco --primary

                &__content {
                    text-align: left;

                    @media screen and (max-width: 700px) {
                        max-width: 400px;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    p {
                        margin-bottom: 1em;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    ul {
                        list-style: none;
                        padding-left: 1em;
                        margin-bottom: 1em;
    
                        &:last-child {
                            margin-bottom: 0;
                        }
    
                        li {
                            position: relative;
                            display: block;
                            margin-left: 1em;
    
                            &::before {
                                content: "\2022";
                                position: absolute;
                                top: -1px;
                                left: -1em;
                                color: $textLight;
                                width: 1em;
                            }
                        }
                    }
                }
            }
        }

        &__after {
            text-align: center;
            max-width: 560px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 60px;

            br {
                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
        }

        &__bcg { // img outside wrapper
            z-index: 1;

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;

            &__img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: .15;
            }
        }
    }

    .realisations {
        padding-top: 60px;
        padding-bottom: 60px;
        // background-color: $primary;

        @media screen and (max-width: 1000px) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        @media screen and (max-width: 500px) {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        > .wrapper {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 2fr; // repeat(2, 1fr)
            // align-items: center;
            gap: 60px;

            @media screen and (max-width: 900px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 30px;
            }
        }

        &__heading {
            margin-top: 100px; // offset

            @media screen and (max-width: 900px) {
                margin-top: 0;
            }

            &__title {}

            &__content {
                width: 100%;

                @media screen and (max-width: 900px) {
                    max-width: 560px;
                }
            }

            &__buttonContainer {
                margin-top: 40px;

                &__text {
                    font-weight: bold;
                }
                &__button { // .button
                    &--1 {
                        margin-right: 10px;
                    }
                    &--2 {}
                }
            }
        }

        &__itemsContainer {
            width: 100%;

            position: relative;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;

            @media screen and (max-width: 900px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 15px;
            }
            @media screen and (max-width: 900px) {
                grid-template-columns: repeat(3, 1fr);
            }
            @media screen and (max-width: 600px) {
                grid-template-columns: repeat(2, 1fr);
            }

            &__item {
                z-index: 2;
                width: 100%;

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-self: center;
                text-align: center;

                position: relative;
                overflow: hidden;
                // border-radius: 8px;

                // maintain ratio
                &:before {
                    content: '';
                    display: block;
                    padding-top: 100%;
                }

                &:nth-child(1) { // bigger square
                    grid-column: span 2;
                    grid-row: span 2;
                }

                &:nth-child(6) { // bigger square
                    @media screen and (max-width: 600px) {
                        grid-column: span 2;
                        grid-row: span 2;
                    }
                }

                &__link {
                    z-index: 2;
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: inline-block; // safety
                    width: 100%;
                    height: 100%;
                    transform: scale(1);

                    transition: transform .3s $ease;

                    // overlay
                    &:after {
                        content: "";
                        z-index: 3;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        pointer-events: none;
                        // background-color: rgba($primary, .3);
                        background-color: rgba($white, .3);
                        opacity: 0;
                        
                        transition: opacity .3s $ease;
                    }

                    &:hover {
                        transform: scale(1.1);
                        
                        &::after {
                            opacity: 1;
                        }
                    }

                    &__img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    .apropos--2 {} // voir .apropos plus haut // = certifications (equipe!)

    .contact {
        padding-top: 60px;
        padding-bottom: 60px;

        @media screen and (max-width: 1000px) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        @media screen and (max-width: 500px) {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        > .wrapper {
            position: relative;
            text-align: center;
        }

        &__contentContainer {
            max-width: 100%;
            width: 800px;
            margin-left: auto;
            margin-right: auto;

            &__title {} // maintitle
            
            &__content {
                &--1 {}
                &--2 {
                    margin-top: 50px;
                }
            }

            &__buttonsContainer {
                margin-top: 20px;

                display: flex;
                align-items: center;
                justify-content: center;

                &--1 {}
                &--2 {}

                &__text {
                    // min-width: 120px;
                    display: inline-block;
                }
                
                &__button { // .button
                    margin-left: 10px;
                }
            } 
        }

        &__formContainer {
            max-width: 100%;
            width: 800px;
            margin-left: auto;
            margin-right: auto;

        }

        // &__mapContainer {
        //     width: 100%;
        //     height: 500px; // 100%
        //     background-color: $lightgray;
            
        //     @media screen and (max-width: 1000px) {
        //         height: 500px;
        //     }

        //     #map {
        //         width: 100%;
        //         height: 100%;
        //     }
        // }
    }

    .map {
        // padding-top: 60px;

        // @media screen and (max-width: 1000px) {
        //     padding-top: 40px;
        // }
        // @media screen and (max-width: 500px) {
        //     padding-top: 30px;
        // }

        > .wrapper { // --none
            position: relative;
            width: 100%;

            padding: 0 !important; // counter
        }

        &__mapContainer {
            width: 100%;
            height: 500px; // 100%
            background-color: $lightgray;
            
            #map {
                width: 100%;
                height: 100%;
            }
        }
    }

}