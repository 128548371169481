// HEADER

header {
    background-color: $white;

    & > .wrapper { // --none
        z-index: 10;
        display: flex;
        justify-content: flex-start; // space-between;
    
        // reset wrapper
        @media screen and (max-width: 900px) {
            padding-left: 0;
            padding-right: 0;
        }
        @media screen and (max-width: 500px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }   
    }

    .navigation {
        width: 100%;
        padding: 15px 0px;
        margin: 0 auto;

        display: flex;
        justify-content: flex-start; // space-between
        align-items: center;

        font-family: $titleFont;

        @media screen and (min-width: 801px) {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 10;

            // recreate wrapper
            padding-left: 50px;
            padding-right: 50px;

            width: 100%;
            background-color: $white;
            box-shadow: 0 8px 4px -4px rgba($black, .03);
        }
    
        // recreate wrapper
        @media screen and (max-width: 900px) {
            padding-left: 5%;
            padding-right: 5%;
        }

        &__title { 
            margin: 0;

            &__homelink {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                color: $black;
                margin-right: 20px;
                line-height: 1;
                font-size: $x-large;
    
                &:hover {
                    color: $black;
                }

                @media screen and (max-width: 600px) {
                    font-size: $x-large;
                }
                
                span {
                    font-size: $normalmore;

                    @media screen and (max-width: 600px) {
                        font-size: $normal;
                    }
                }
            }
        }

        &__input {}

        &__burger {
            z-index: 11;
            display: block;
            position: absolute;
            top: 20px;
            right: 20px;
            width: 40px;
            height: 40px;
            font-size: 0;
            z-index: 2001;

            transition: color .3s $ease;

            &:hover, &:focus {
                // background-color: darken($purple, 10);
            }
            &::before {
                text-align: center;
                content: "\2261";
                font-size: 40px;
                line-height: 40px;
                display: block;
                cursor: pointer;
                // color: $white;
                // text-shadow: 1px 1px 1px $shadow;
            }

            @media screen and (min-width: 801px) {
                display: none;
            }
        }
        #toogle-nav:checked ~ label{
            // background-color: darken($purple, 10);
            transform: scale(.9);
        }

        &__list {
            display: flex;
            flex-direction: row;
            list-style: none;
            
            // li:first-child {
            //     a {
            //         margin-left: 0;
            //     }
            // }
            // li:last-child {
            //     a {
            //         margin-right: 0;
            //     }
            // }

            @media screen and (max-width: 800px) {
                opacity: 0;
                pointer-events: none;
                visibility: hidden;
                
                display: none;
                
                li {
                    margin: 20px;

                    a {
                        margin: 0;
                    }
                }
            }

            // &.open {
            //     display: block;
            // }
        }
        #toogle-nav:checked ~ ul{
            // only on mobile !
            @media screen and (max-width: 800px) {
                opacity: 1;
                z-index: 2000;

                position: fixed;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background: $color__white;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                pointer-events: auto;
                visibility: visible;

                transition: color .3s $ease;
            }
        }
    
        &__elt {}

        &__link {
            margin: 0px 10px;
            padding: 6px;

            font-family: $titleFont;
            font-size: $small;
            color: $text;
            font-weight: bold;
            position: relative;

            @media screen and (max-width: 1000px) {
                margin: 0px 5px;
            }

            &:focus, &:hover {
                color: $primary;
            }

            &--primary {
                color: $primary;
            }
        }
    }
}