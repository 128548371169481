// FOOTER

.footer { // footer
    padding-top: 30px;
    padding-bottom: 30px;

    background-color: $bcgDark;
    color: $white;

    & > .wrapper { // --none
        z-index: 2;
        display: flex;
        justify-content: space-between;
    
        @media screen and (max-width: 500px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 30px;
        }   
    }

    &__title {
        margin: 0;

        &__homelink {
            display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                color: $white;
                margin-right: 20px;
                line-height: 1;
                font-size: $x-large;
    
                &:hover {
                    color: $white;
                }

                @media screen and (max-width: 600px) {
                    font-size: $x-large;
                }
                @media screen and (max-width: 500px) {
                    margin-right: 0;
                } 
                
                span {
                    font-size: $normalmore;

                    @media screen and (max-width: 600px) {
                        font-size: $normal;
                    }
                }
        }
    }

    &__legal {
        display: flex;
        align-items: center;

        &__link {
            color: $white;
            font-size: $small;
            text-decoration: underline;

            &:hover {
                color: $white;
            }
        }

        &__date {
            margin-left: 20px;
            color: $white;
            font-size: $small;
        }
    }
}