// Colors
$color__white: #ffffff;
$color__black: #000000;
$white: $color__white;
$black: $color__black;

$color__primary: #e17600; // #152E4F;
$color__primary--dark: darken($color__primary, 10);
$color__secondary: #292929;

$primary: $color__primary;
$primaryDark: $color__primary--dark;
$secondary: $color__secondary; // text

$bcgDark: $secondary; 
$text: $secondary;
$textLight: lighten($secondary, 15);

$color__gray: #F4F7F6;
$color__shadow: rgba(#000000, .1);
$gray: $color__gray;
$shadow: $color__shadow;

$color__border: rgba(#707070, .4);
$color__lightgray: #F4F7F6;

$border: $color__border;
$lightgray: $color__lightgray;

// Transitions
$mainEase: ease-in-out;
$ease: $mainEase;

// @mixin transition {
//     transition: all .25s $mainEase;
// }

