// tag --------------------------------------------
html {
    font: normal 16px $textFont; // 1rem = $normal
    color: $text;
    overflow-x: hidden;
    background-color: $color__white;
}

body {
    font-size: $normal;
    line-height: 1.5;
    font-weight: 400;
    font-family: $textFont;
    position: relative;
}

main {
    position: relative;
    // display: flex;
    // flex-flow: row wrap;
    // padding-top: 5rem;
}

section {
    position: relative;
    // display: flex;
    // overflow: hidden;
    // width: 100%;
    // padding: 2rem 5%;
    // margin-bottom: 1rem;
    // border: 1px solid $color__gray; // TEMP to structure
}

.hidden {
    display: none;
}

.wrapper {
    position: relative;
    width: 100%;
    max-width: calc(1240px + 100px); // calc(1440px + 100px)
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;

    @media screen and (max-width: 900px) {
        padding-left: 5%;
        padding-right: 5%;
    }

    &--small {
        max-width: calc(940px + 100px);
    }
    
    &--none {
        max-width: 100%;
    }
}

div {
    position: relative;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: $primary;

    transition: color .3s $ease;

    &:focus, &:hover {
        color: lighten($primary, 10);
    }
}

img {
    display: block;
    max-width: 100%;
}

// titles, golden proportions ---------------------
h1, h2, h3, h4, h5, h6 {
    display: inline-block;
    color: $color__black;
    font-weight: bold;
    // text-transform: uppercase;
    margin-bottom: .5em;
}
h1 {
    font-size: $xxx-large;
    font-family: $titleFont;
}
h2 {
    font-size: $xx-large;
    font-family: $titleFont;
}
h3 {
    font-size: $x-large;
    font-family: $titleFont;
}
h4 {
    font-size: $large;
    font-family: $titleFont;
}

p {
    margin-bottom: .5em;
}

b, strong {
    font-weight: bold;
}
i, em {
    font-style: italic;
}



// selection --------------------------------------    
::selection {
    color: $color__white;
    background: $primary;
}

// scroll bar custom
::-webkit-scrollbar {
  max-width: 10px;
  max-height: 10px;
  background: $color__gray;
}

::-webkit-scrollbar-track, ::-webkit-scrollbar-corner {
  background: $color__gray;
}

::-webkit-scrollbar-thumb {
  background: rgba($primary, .7);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba($primary, .9);
}

// mixins -----------------------------------------
@mixin transition {
    transition: .2s $mainEase;
    transition-delay: 0;
}


// class ------------------------------------------
.hidden {
    display: none;
}

.center { // check body
    max-width: 1440px;
    margin: 1rem auto;
}

.maintitle {
    font-size: $x-large;
    font-family: $titleFont;
    position: relative;
    margin-bottom: .8em;
    padding-bottom: .3em;
    margin-top: 0;
    color: $black;

    // old - line
    // &:after {
    //     content: '';
    //     width: 30px;
    //     height: 2px;
    //     background-color: $primary;
    //     position: absolute;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     bottom: 0px;
    // }

    // square 1 l
    &::before {
        content: '';
        width: 7px;
        height: 7px;
        background-color: $primary;
        position: absolute;
        left: calc(50% - 6px);
        transform: translateX(-50%);
        bottom: 0px;
        pointer-events: none;
    }

    // square 2 r
    &:after {
        content: '';
        width: 7px;
        height: 7px;
        background-color: $primary;
        position: absolute;
        left: calc(50% + 6px);
        transform: translateX(-50%);
        bottom: 0px;
        pointer-events: none;

    }
    
    // &--white {
    //     color: $white;

    //     &:after {
    //         background-color: $white;
    //     }
    // }

    &--secondary {
        color: $textLight;

        &:before, &:after {
            background-color: $textLight;
        }
    }

    &--primary {
        color: $primary;
    }

    &--large {
        font-size: $xx-large;
    }

    &--small {
        font-size: $large;
    }
    &--smaller {
        font-size: $normalmore;
    }

    &--nodeco {
        padding-bottom: 0;

        &:before, &:after {
            display: none;
        }
    }
}

.uptitle {
    font-size: $small;
    font-family: $cursiveFont;
    margin: 0;
}

// scrollreveal
.load-hidden {
    visibility: hidden; // js
}