// BUTTONS

.button {
	position: relative;
	z-index: 2;
	overflow: hidden;
	display: inline-block;
	background-color: $primary;
	color: $white;
	padding: 10px 30px;
	border-radius: 8px;
	text-transform: uppercase;
	font-weight: bold;
	font-size: $small;
	font-family: $titleFont;
	text-transform: none;
	border: 2px solid $primary;

	transition: color .3s $ease, background-color .3s $ease, border .3s $ease;

	span {
		position: relative;
		z-index: 3;
		display: inline-block;
		color: $white;
		transform: scale(1);

		transition: transform  .3s $ease;
	}

	&::before {
		content: '';
		z-index: 1;
		position: absolute;
		top: 0; // 2px
		bottom: 0; // 2px
		left: 0; // 2px
		width: 0;
		pointer-events: none;
		background-color: $secondary;
		background: linear-gradient(90deg, rgba($secondary, 1) 0%, rgba($secondary, 1) 90%, rgba($secondary, 0) 100%);
		border-radius: 6px;

		transition: width  .2s $ease;
	}

	&:focus, &:hover {
		border: 2px solid $secondary;
		background-color: $secondary;
		color: $white;

		span {
			transform: scale(1.1);
		}

		&::before {
			width: 110%; // calc(100% - 4px)
		}
	}

	// seondary
	&--secondary {
        color: $secondary;

    	&:focus, &:hover {
			background-color: darken($secondary, 10);
		}
	}

	// reverse + border
	// &--reverse {
	// 	border: 2px solid $primary;
	// 	background-color: $white;
	// 	color: $primary;

	// 	&:focus, &:hover {
	// 		border: 2px solid $primary;
	// 		background-color: $primary;
	// 		color: $white;
	// 	}
	// }

	// jus text
	&--text {
		border: none;
		background: none;
		color: $primary;
		padding: 0;
		font-size: $normalmore;
		font-weight: bold;
		border-radius: 0;

		// counter
		&::before {
			top: inherit;
			height: 2px;
			border-radius: 0;
			background: $secondary;
		}

		// counter
		&:focus, &:hover {
			border: none;
			background: none;
			color: $secondary;
			padding: 0;

			&::before {
				// display: none;
				width: 100%;
			}
		}
	}
}