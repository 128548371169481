
// size golden proportions scale
// 0.382em
// 0.618em
$x-small  : 0.618rem;
$small    : 0.887rem;
$normal   : 1rem;
$normalmore   : 1.1rem; // ?
$large    : 1.272rem;
$x-large  : 1.618rem;
$xx-large : 2.058rem;
$xxx-large: 2.618rem;
// 2.953em
// 3.33em
// 3.756em
// 4.236em
// 4.778em

// Variables
$textFont: 'Nunito Sans', sans-serif;
$titleFont: $textFont;
$cursiveFont: $textFont; //

$glyphs: 'fontawesome';
 
// // Fontawesome
// @font-face {
//     font-family: 'fontawesome';
//     src: url('../font/fontawesome/fontawesome.otf');
//     src: url('../font/fontawesome/fontawesome-webfont.eot') format('embedded-opentype'),
//          url('../font/fontawesome/fontawesome-webfont.woff') format('woff'),
//          url('../font/fontawesome/fontawesome-webfont.woff2') format('woff2'),
//          url('../font/fontawesome/fontawesome-webfont.ttf') format('truetype'),
//          url('../font/fontawesome/fontawesome-webfont.svg') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

// Nunito sans: 300, //400, 700

// ;400
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;700&display=swap');