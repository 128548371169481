// PAGE LEGAL

.page__legal {
	padding-top: 71px; // 73 // space header

    // burger
    @media screen and (max-width: 800px) {
        padding-top: 0;
    }

	.contenu {
		padding-top: 60px;
		padding-bottom: 60px;
		min-height: 500px;

        > .wrapper {} // --smaller

		&__heading {
			text-align: center;

			&__title {} // maintitle
		}
		
		&__content { // div
			padding-top: 50px;
		}
    }
}